// @flow

import React from 'react'
import { useIntl } from 'react-intl'

import { useTranslateObject, withIntl } from 'src/intl'

import { Layout, Navbar, BackgroundImage, PageRow } from 'src/common/components'
import { ButtonLink } from 'src/common/components/Button'
import { linkToIndexPage } from 'src/links'

import styles from './404.module.scss'

const browser = typeof window !== 'undefined' && window

const strings = {
  title: 'notFoundPage.title',
  buttonText: 'notFoundPage.buttonText',
}

function NotFoundPage() {
  const messages = useTranslateObject(strings)
  const { locale } = useIntl()

  return (
    // stop a flash of 404 page when redirecting
    browser && (
      <Layout>
        {{
          navbar: <Navbar />,
          background: <BackgroundImage />,
          body: (
            <PageRow firstRow className={styles.container}>
              <div className={styles.header}>404</div>
              <div className={styles.content}>{messages.title}</div>
              <ButtonLink
                text={messages.buttonText}
                linkExternal={linkToIndexPage(locale)}
                color="white"
                external={false}
              />
            </PageRow>
          ),
        }}
      </Layout>
    )
  )
}

export default withIntl(NotFoundPage)
